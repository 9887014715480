import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const user = store.getters.user;

const routes = [
	{
		path: "/",
		redirect: "/Analytics",
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("../views/Login.vue"),
	},
	{
		path: "/PopularDishes",
		name: "PopularDishes",
		component: () => import("../views/PopularDishes.vue"),
	},
	{
		path: "/Restaurants",
		name: "Restaurant",
		component: () => import("../views/Restaurants"),
		meta: { requiresAuth: true },
	},
	{
		path: "/dialogWindow",
		name: "dialogWindow",
		component: () => import("../views/dialogWindow.vue"),
	},
	{
		path: "/Profile",
		name: "Profile",
		component: () => import("../views/Profile"),
	},
	{
		path: "/Guest",
		name: "Guest",
		component: () => import("../views/Guest"),
	},
	{
		path: "/Staff",
		name: "Staff",
		component: () => import("../views/Staff"),
	},
	{
		path: "/Menu",
		name: "Menu",
		component: () => import("../views/Menu"),
	},
	{
		path: "/AppVersion",
		name: "AppVersion",
		component: () => import("../views/AppVersion"),
	},
	{
		path: "/Firebase",
		name: "Firebase",
		component: () => import("../views/Firebase"),
	},
	{
		path: "/Stats",
		name: "Stats",
		component: () => import("../views/Stats"),
	},
	{
		path: "/admin/product",
		name: "ProductList",
		component: () => import("../views/ProductList.vue"),
	},
	{
		path: "/admin/product",
		name: "ProductList",
		component: () => import("../views/ProductList.vue"),
	},
	{
		path: "/RestaurantPage/:restaurantId",
		name: "RestaurantPage",
		component: () => import("../views/RestaurantPage"),
	},
	{
		path: "/ProductPage/:productId",
		name: "ProductPage",
		component: () => import("../views/ProductPage"),
	},
	{
		path: "/RestaurantChoose",
		name: "RestaurantChoose",
		component: () => import("../views/RestaurantChoose.vue"),
		beforeEnter: (to, from, next) => {
			// Здесь вы можете добавить условия
			// Если условия выполняются, то разрешите переход на страницу
			// В противном случае, перенаправьте пользователя на другой маршрут или выполните другие действия

			if (Object.keys(store.state.user.currentRest).length === 0) {
				next(); // Разрешить переход
			} else {
				next(`/RestaurantPage/${store.state.user.currentRest.restaurant.id}`); // Перенаправить пользователя на другую страницу
			}
		},
	},
	{
		path: "/RestaurantRoot",
		name: "RestaurantRoot",
		component: () => import("../views/RestaurantRoot.vue"),
	},
	{
		path: "/admin/manufacturer",
		name: "ManufacturerList",
		component: () => import("../views/ManufacturerList.vue"),
	},
	{
		path: "/admin/manufacturer/:manufacturerId",
		name: "ManufacturerPage",
		component: () => import("../views/ManufacturerPage.vue"),
	},
	{
		path: "/AccountList",
		name: "AccountList",
		component: () => import("../views/AccountList.vue"),
	},
	{
		path: "/AccountPage",
		name: "AccountPage",
		component: () => import("../views/AccountPage"),
	},
	{
		path: "/admin/applications",
		name: "ApplicationList",
		component: () => import("../views/ApplicationList"),
	},
	{
		path: "/admin/application/:applicationId",
		name: "ApplicationPage",
		component: () => import("../views/ApplicationPage"),
	},
	{
		path: "/CreateNewRest",
		name: "CreateNewRest",
		component: () => import("../views/CreateNewRest"),
	},
	{
		path: "/GuestDashboard",
		name: "GuestDashboard",
		component: () => import("../views/GuestMembers"),
	},
	{
		path: "/LoyaltyProgramOperation",
		name: "LoyaltyProgramOperation",
		component: () => import("../views/LoyaltyProgramOperation"),
	},
	{
		path: "/CouponOperation",
		name: "CouponOperation",
		component: () => import("../views/CouponOperation"),
	},
	{
		path: "/DeliveryOperation",
		name: "DeliveryOperation",
		component: () => import("../views/DeliveryOperation"),
	},
	{
		path: "/Payment",
		name: "Payment",
		component: () => import("../views/Payment"),
	},
	{
		path: "/LoyaltyProgram",
		name: "LoyaltyProgram",
		component: () => import("../views/LoyaltyProgram"),
	},
	{
		path: "/error",
		name: "ErrorPage",
		component: () => import("../views/ErrorPage"),
	},
	{
		path: "/Coupon",
		name: "Coupon",
		component: () => import("../views/Coupon"),
	},
	{
		path: "/Delivery",
		name: "Delivery",
		component: () => import("../views/Delivery"),
	},
	{
		path: "/DeliveryPage",
		name: "DeliveryPage",
		component: () => import("../views/DeliveryPage"),
	},
	{
		path: "/UserMembers",
		name: "UserMembers",
		component: () => import("../views/UserMembers"),
	},
	{
		path: "/UserMemberCard",
		name: "UserMemberCard",
		component: () => import("../views/UserMemberCard"),
	},
	{
		path: "/UserMembersApp",
		name: "UserMembersApp",
		component: () => import("../views/UserMembersApp"),
	},
	{
		path: "/Analytics",
		name: "Analytics",
		component: () => import("../views/Analytics"),
	},
	{
		path: "/OrderPage",
		name: "OrderPage",
		component: () => import("../views/OrderPage"),
	},
	{
		path: "/ReviewsPage",
		name: "ReviewsPage",
		component: () => import("../views/ReviewsPage"),
	},
];

const router = new VueRouter({
	mode: "history", // Устанавливаем режим "history"
	base: "/",
	routes,
});

router.beforeEach(async (to, from, next) => {
	if (!store.state.user.logged) {
		// Пользователь не залогинен или нет актуальных токенов
		// await store.dispatch("fetchUser");
	} // Попытка загрузки пользователя
	if (to.name !== "Login" && !user.logged) {
		next({ name: "Login" }); // Пользователь не залогинен - перенаправляем на страницу авторизации
	} else {
		next(); // Все в порядке, продолжаем нормальный переход на запрошенную страницу
	}
});
// router.beforeEach(async (to, from, next) => {
//   if (!user.logged) {
//     // Если пользователь не аутентифицирован, попробуем выполнить автоматическую авторизацию
//     try {
//       await store.dispatch("fetchUserFromFirebase"); // Асинхронный вызов для авторизации
//       next(); // После успешной авторизации, продолжаем далее
//     } catch (error) {
//       // Если авторизация не удалась, перенаправляем на страницу логина
//       next({ name: "Login" });
//     }
//   } else {
//     next(); // Если пользователь уже аутентифицирован, продолжаем далее
//   }
export default router;
